/**
 * PDEV front environment API paths
 */

const front_Webshop_shop_shoppingcart_url = API_entry + 'Webshop/Shop/ShoppingCart.php';
const front_Webshop_shop_url = API_entry + 'Webshop/Shop/Shop.php';


const front_PaymentScreen_url = API_entry + 'Payments/PaymentScreen/PaymentScreen.php';
const front_Customer_url = API_entry + 'Customer/CustomerAuthentication.php';
const front_CMS_url = API_entry + 'CMS/CMS_front.php';
const front_OpeningHours_url = API_entry + 'Company/OpeningHours.php';